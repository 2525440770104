const PRIMARY_COLOR = "#008080";
const PRIMARY_COLOR_LIGHT = "#b3d8d8";
const DARK_PRIMARY = "#004c4c"
const DANGER_COLOR = "#fa6778"
const SECONDARY_COLOR = "#A6EFEF";
const SECONDARY_LIGHT_COLOR = "#F9F9F9";

const WHITE_COLOR = "#FFFFFF";

const GRAY_COLOR_ONE = "#333333";
const BLACK_COLOR = "#000000";

export {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  WHITE_COLOR,
  GRAY_COLOR_ONE,
  SECONDARY_LIGHT_COLOR,
  BLACK_COLOR,
  PRIMARY_COLOR_LIGHT,
  DARK_PRIMARY,
  DANGER_COLOR
};
